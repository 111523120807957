import { Stripe as StripeTypes, CanadianState, Country } from '../types';
function getProductTierKey(
  env: 'production' | 'development',
  productTierValue: StripeTypes.ProductTierValue
): StripeTypes.ProductTierKey {
  const productTier = productTiers[env][productTierValue];
  if (!productTier) {
    throw Error('getProductTierKey: ProductTierKey not found');
  }

  return productTier;
}

const productTiers: {
  [key in 'production' | 'development']: StripeTypes.ProductTier;
} = {
  production: {
    prod_PAB8w7q3DtUWeI: StripeTypes.ProductTierKey.up_to_100,
    prod_PAB8MWmdm0Z2wG: StripeTypes.ProductTierKey.up_to_250,
    prod_PAB8wbDSutUiwd: StripeTypes.ProductTierKey.up_to_500,
    prod_PAB85xXGdI8thK: StripeTypes.ProductTierKey.up_to_1000,
    prod_PAB8OA1vvBdBZ6: StripeTypes.ProductTierKey.up_to_5000,
    prod_PAB8GsgKNwHqbv: StripeTypes.ProductTierKey.up_to_20000,
  },
  development: {
    prod_P8Jly742Xvtl04: StripeTypes.ProductTierKey.up_to_100,
    prod_P8Jp1YLbqlYtPF: StripeTypes.ProductTierKey.up_to_250,
    prod_P8JtksvkvqtBLk: StripeTypes.ProductTierKey.up_to_500,
    prod_P8JvyzpMytNfQQ: StripeTypes.ProductTierKey.up_to_1000,
    prod_P8JwwIyB1tO8lq: StripeTypes.ProductTierKey.up_to_5000,
    prod_P8JyH7rrI3W0BN: StripeTypes.ProductTierKey.up_to_20000,
  },
};

function getYearlyPriceId(env: 'production' | 'development', numberOfDonors: number): string {
  if (numberOfDonors < 100) {
    return donorCountToPriceMap[env][StripeTypes.ProductTierKey.up_to_100];
  }
  if (numberOfDonors < 250) {
    return donorCountToPriceMap[env][StripeTypes.ProductTierKey.up_to_250];
  }
  if (numberOfDonors < 500) {
    return donorCountToPriceMap[env][StripeTypes.ProductTierKey.up_to_500];
  }
  if (numberOfDonors < 1000) {
    return donorCountToPriceMap[env][StripeTypes.ProductTierKey.up_to_1000];
  }
  if (numberOfDonors < 5000) {
    return donorCountToPriceMap[env][StripeTypes.ProductTierKey.up_to_5000];
  }
  if (numberOfDonors < 20000) {
    return donorCountToPriceMap[env][StripeTypes.ProductTierKey.up_to_20000];
  }

  throw Error('getYearlyPriceId: YearlyPriceId not found');
}

const donorCountToPriceMap: {
  [key in 'production' | 'development']: { [key in StripeTypes.ProductTierKey]: string };
} = {
  production: {
    up_to_100: 'price_1OLqmBIiJQgCcrxer92QuNMp',
    up_to_250: 'price_1OLqmJIiJQgCcrxeP5oSh7VG',
    up_to_500: 'price_1OLqmNIiJQgCcrxeGNxaRFFT',
    up_to_1000: 'price_1OLqmVIiJQgCcrxe61JkNXOJ',
    up_to_5000: 'price_1OLqmYIiJQgCcrxeWpGoGVL9',
    up_to_20000: 'price_1OLqmcIiJQgCcrxeIDf2pLbx',
  },
  development: {
    up_to_100: 'price_1OK38gIiJQgCcrxeOIfJxJgl',
    up_to_250: 'price_1OK3BQIiJQgCcrxebjpEX4tV',
    up_to_500: 'price_1OK3FMIiJQgCcrxe2S10hNuy',
    up_to_1000: 'price_1OK3HFIiJQgCcrxeARwHS8Yd',
    up_to_5000: 'price_1OK3IqIiJQgCcrxeVzYQKkTO',
    up_to_20000: 'price_1OK3KEIiJQgCcrxeHXT6VGN7',
  },
};

function mapRecurringPriceToInterval(
  priceInterval?: 'day' | 'week' | 'month' | 'year'
): StripeTypes.RecurringInterval {
  if (priceInterval === 'month') {
    return StripeTypes.RecurringInterval.monthly;
  }
  return StripeTypes.RecurringInterval.yearly;
}

// For now the only tax we need to apply is for Canadian states
const stateToTax: {
  [key in 'production' | 'development']: { [key in CanadianState]: string };
} = {
  production: {
    ab: 'txr_1QKFsaIiJQgCcrxeKxGNufAy',
    bc: 'txr_1QKFtKIiJQgCcrxewSXeOEOe',
    mb: 'txr_1QKFuOIiJQgCcrxemrijodTx',
    nl: 'txr_1QKFy1IiJQgCcrxe6krCJp8R',
    nb: 'txr_1QKFx1IiJQgCcrxeX0aEVvRS',
    nt: 'txr_1QKFv9IiJQgCcrxeakgPggiy',
    ns: 'txr_1QKFyXIiJQgCcrxeGNK48eOF',
    nu: 'txr_1QKFvjIiJQgCcrxegm5NA38t',
    on: 'txr_1QKG1OIiJQgCcrxeYTc1gLhb',
    pe: 'txr_1QKFzXIiJQgCcrxeagArVunZ',
    qc: 'txr_1QKG0FIiJQgCcrxeKJIcmdzh',
    sk: 'txr_1QKG1xIiJQgCcrxeXqczw3p9',
    yt: 'txr_1QKFwHIiJQgCcrxe1JKGsx6b',
  },
  development: {
    ab: 'txr_1QKM0TIiJQgCcrxeiGWQAODf',
    bc: 'txr_1QKM1VIiJQgCcrxeZQLJ4nQJ',
    mb: 'txr_1QKM1rIiJQgCcrxe9uqzpuMN',
    nl: 'txr_1QKM4OIiJQgCcrxe4jkeZeJ7',
    nb: 'txr_1QKM3xIiJQgCcrxeHk1n7mbu',
    nt: 'txr_1QKM2NIiJQgCcrxeszWpFPeV',
    ns: 'txr_1QKM4pIiJQgCcrxe9AKtRqEh',
    nu: 'txr_1QKM2oIiJQgCcrxeffH0kdhr',
    on: 'txr_1QKM5OIiJQgCcrxeiNIJXQVA',
    pe: 'txr_1QKM5pIiJQgCcrxe3C0MV8Js',
    qc: 'txr_1QKM6IIiJQgCcrxevK5j94Vk',
    sk: 'txr_1QKM6hIiJQgCcrxeUDEUVBQd',
    yt: 'txr_1QKM3HIiJQgCcrxeslCgCPDS',
  },
};

function getTaxRate(
  env: 'production' | 'development',
  country: Country,
  state?: string | CanadianState
): string | undefined {
  // The tax can only be applied to Canadian states/provinces
  // When we can't find the correct canadian state then we don't want to apply tax
  if (country !== Country.ca || !state) return;
  // Check that state is a valid value
  if (!Object.values(CanadianState).includes(state as CanadianState)) return;

  return stateToTax[env][state as CanadianState];
}

const StripeUtil = { getProductTierKey, mapRecurringPriceToInterval, getYearlyPriceId, getTaxRate };
export { StripeUtil };
