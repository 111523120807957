import {
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Box,
  TableRow,
  TableCell,
  Typography,
  Stack,
  Link,
} from '@mui/material';
import _orderBy from 'lodash/orderBy';
import { Link as RouterLink } from 'react-router-dom';

import PATHS, { getPath } from '@routes/paths';
import { TColumn } from '@typedefs/app';
import { TDonorWithDonations } from '@typedefs/donation';
import useTable, { emptyRows } from '@hooks/useTable';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';
import useNavigate from '@hooks/useNavigate';
import { Donation } from '@shared/types';

import { TableNoData, TableEmptyRows, TableHeadCustom } from '@components/table';
import Scrollbar from '@components/Scrollbar';

// ----------------------------------------------------------------------
const TABLE_HEAD: TColumn[] = [
  { id: 'date', type: 'date', label: 'Date', align: 'left', visible: true },
  { id: 'amount', type: 'number', label: 'Amount', align: 'right', visible: true },
  { id: 'paymentMethod', type: 'string', label: 'Payment method', align: 'left', visible: true },
  { id: 'category', label: 'Category', align: 'left', visible: true },
  { id: 'receiptNumber', type: 'string', label: 'Receipt #', align: 'left', visible: true },
];

// ----------------------------------------------------------------------
type Props = {
  donor: TDonorWithDonations;
  context: 'donors' | 'donations' | 'issue' | 'reissue' | 'reissue-batch';
  nonReceiptable?: boolean;
};
// ----------------------------------------------------------------------
export default function DonorDonationList({ donor, context, nonReceiptable }: Props) {
  const navigate = useNavigate();
  const { getDonationCategory, getDonationReceipt, getPaymentMethod } = useDonation();
  const { fDate, fCurrency, fReceiptNumber } = useFormat();
  const { columns, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage, onSort } =
    useTable({
      defaultRowsPerPage: 5,
      defaultColumns: TABLE_HEAD,
      defaultOrderBy: 'date',
      defaultOrder: 'desc',
    });
  const donations = _orderBy(donor.donations, orderBy, order);
  nonReceiptable = nonReceiptable ?? donor.nonReceiptable;

  const handleDonationClick = (donationId: string) => {
    if (context === 'issue') {
      navigate(PATHS.org.receipts.create.donation, {
        donationId,
      });
    } else if (context === 'reissue') {
      navigate(PATHS.org.receipts.reissue.donation, {
        donationId,
      });
    } else if (context === 'reissue-batch') {
      navigate(PATHS.org.receipts.reissueBatch.root, {
        donationId,
      });
    } else {
      navigate(PATHS.org.donors.donor.donation.edit, {
        donationId,
      });
    }
  };

  const formatReceipt = (donation: Donation.Donation) => {
    const receipt = getDonationReceipt(donation);
    if (receipt) {
      return fReceiptNumber(receipt.number, receipt.year);
    } else if (nonReceiptable || donation.nonReceiptable) {
      return 'Non-Receiptable';
    }
    return '';
  };

  return (
    <>
      <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
        <Scrollbar>
          <Table stickyHeader size="small">
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              columns={columns}
              rowCount={donations.length}
              onSort={onSort}
            />

            <TableBody>
              {donations
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((donation) => {
                  const formattedReceipt = formatReceipt(donation);
                  return (
                    <TableRow
                      key={donation.id}
                      hover
                      sx={{ cursor: 'pointer', height: 44 }}
                      onClick={() => handleDonationClick(donation.id)}
                    >
                      <TableCell align="left">{fDate(donation.date)}</TableCell>
                      <TableCell align="right">{fCurrency(donation.amount)}</TableCell>
                      <TableCell align="left">
                        {getPaymentMethod(donation.paymentMethodId, donation.paymentInfo)}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={getDonationCategory(donation)?.description}
                          placement="top"
                          enterDelay={600}
                        >
                          <Stack direction="row" alignItems="center">
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                width: 16,
                                height: 16,
                                margin: 0.5,
                                marginRight: 1,
                                borderRadius: '50%',
                                background:
                                  getDonationCategory(donation)?.colorBackground || undefined,
                              }}
                            />
                            <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(14) }}>
                              {getDonationCategory(donation)?.name || ''}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={(e) => {
                          if (formattedReceipt && formattedReceipt !== 'Non-Receiptable') {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                      >
                        {' '}
                        {formattedReceipt && formattedReceipt !== 'Non-Receiptable' ? (
                          <Link
                            variant="body2"
                            component={RouterLink}
                            to={`${getPath(PATHS.org.receipts.view, {
                              orgId: donation.orgId,
                              receiptId: donation.receiptIds[donation.receiptIds.length - 1],
                            })}`}
                            sx={{ color: 'initial' }}
                          >
                            {formattedReceipt}
                          </Link>
                        ) : (
                          formattedReceipt
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

              <TableEmptyRows
                height={44}
                emptyRows={emptyRows(page, rowsPerPage, donations.length)}
              />

              {/* change this isNotFound */}
              <TableNoData isNotFound={!donations.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={donations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
