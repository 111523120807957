import { useMemo, useState } from 'react';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportLayout from '../../ReportLayout';
import { filter, first } from 'lodash';
import {
  AvailableSortOrder,
  SortOrder,
  orderDescription,
  sortByOrders,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import ReportViewer from '@pages/reports/ReportViewer';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const { fDate, fCurrency, fDateToISO, fReversedName } = useFormat();
  const { donorsWithDonations, getYears, getCategoryById, getPaymentMethod } = useDonation();
  const { org } = useOrg();

  // ---------- form state
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([
    { availablesIndex: 1 },
    { availablesIndex: 0 },
    { availablesIndex: 3 },
  ]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [reportLoading, setReportLoading] = useState(false);

  // period management
  const years = getYears();

  const [yearSelected, setYearSelected] = useState(Number(first(years)));

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Name', field: (d) => d.name.toUpperCase() },
      { label: 'Date', field: (d: any) => fDateToISO(d.date) },
      { label: 'Amount', field: 'amount' },
      { label: 'Category', field: (d) => d.category.toUpperCase() },
      { label: 'Payment Method', field: (d) => d.paymentMethod.toUpperCase() },
    ],
    [fDateToISO]
  );

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 23 },
      { name: 'Date', width: 18 },
      { name: 'Amount ($)', width: 12, align: 'right' },
      { name: 'Category', width: 25 },
      { name: 'Payment Method', width: 20 },
    ];

    const filtered = filter(donorsWithDonations, (d) => !d.nonReceiptable);
    let total = 0;
    const itemsUnsorted = filtered
      .map((donor) => {
        const name = fReversedName(donor);
        return filter(
          donor.donations,
          (donation) =>
            !donation.nonReceiptable &&
            new Date(donation.date).getFullYear() === yearSelected &&
            (!donation.receiptIds || donation.receiptIds.length === 0)
        ).map((d) => {
          total += d.amount;
          return {
            name: name,
            date: new Date(d.date),
            amount: d.amount,
            category: getCategoryById(d.categoryId)?.name || '',
            paymentMethod: getPaymentMethod(d.paymentMethodId, d.paymentInfo),
          };
        });
      })
      .flat();
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((d) => [
      d.name,
      fDate(d.date),
      fCurrency(d.amount),
      d.category,
      d.paymentMethod,
    ]);
    const summary: PdfTableSummaryField[] = [
      { columns: 2, text: `Totals: ${items.length} donations` },
      { columns: 1, text: `${fCurrency(total)}` },
    ];
    return { header, items, summary };
  }, [
    donorsWithDonations,
    sortOrders,
    availableSortOrders,
    fCurrency,
    fReversedName,
    yearSelected,
    getCategoryById,
    getPaymentMethod,
    fDate,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const handleReportLoading = (isLoading: boolean) => {
    setReportLoading(isLoading);
  };

  const handleComplete = (pdfBlob: Blob) => {
    setPdfBlob(pdfBlob);
  };

  return (
    <Dialog
      title="Unreceipted but Receiptable Donations report"
      sx={{ '& .MuiPaper-root': { height: '100vh' } }}
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <LoadingButton
          size="large"
          variant="contained"
          disabled={!pdfBlob}
          loading={reportLoading}
          onClick={handleOpenMenu}
        >
          Download
        </LoadingButton>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'unreceipted_donations'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center" height="100%">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography>For year: </Typography>
              <Autocomplete
                id="select-year"
                size="small"
                options={years}
                sx={{ minWidth: 100 }}
                autoComplete
                autoHighlight
                disableClearable
                freeSolo={false}
                value={yearSelected.toString()}
                onChange={(e, newValue) => setYearSelected(Number(newValue))}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9} height="100%">
          <ReportViewer
            onComplete={handleComplete}
            onLoading={handleReportLoading}
            documentProps={{
              title: name,
              description,
              dateReport: new Date(),
              dateFrom: new Date(Number(yearSelected), 0, 1),
              dateTo: new Date(Number(yearSelected), 11, 31),
              orderBy: orderDescription(sortOrders, availableSortOrders),
              orgName: org!.name,
              data,
              orientation: orientation,
              size: PageSize[size],
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
