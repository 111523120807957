import { Category, Tag, Donation } from '@shared/types';
import { TColumn } from '@typedefs/app';
// ----------------------------------------------------------------------
export type DonationListItem = {
  readonly id: string;
  readonly _donation: Donation.Donation;
  readonly _tags: Tag.Tag[];
  readonly _category?: Category.Category;
  readonly donorId: string;
  readonly receiptId?: string;

  name: string;
  reversedName: string;
  memberNumber?: string;
  email: string;
  date: string;
  amount: string;
  amountEligible: string;
  amountAdvantage: string;
  withAdvantage: string;
  tags: string;
  category: string;
  description: string;
  paymentMethod: string;
  notes: string;
  nonReceiptable: string;
  receipt: string;
};

// ----------------------------------------------------------------------
export type DonationListHeader =
  | 'name'
  | 'reversedName'
  | 'memberNumber'
  | 'email'
  | 'date'
  | 'amount'
  | 'amountEligible'
  | 'amountAdvantage'
  | 'withAdvantage'
  | 'tags'
  | 'category'
  | 'description'
  | 'paymentMethod'
  | 'notes'
  | 'nonReceiptable'
  | 'receipt';

export const DONATION_LIST_HEADERS: TColumn<DonationListHeader>[] = [
  {
    id: 'name',
    type: 'string',
    label: 'Donor Name',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'reversedName',
    type: 'string',
    label: 'Donor Reversed Name',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'memberNumber',
    type: 'string',
    label: 'Member/Env. #',
    align: 'right',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'email',
    type: 'string',
    label: 'Email',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'tags',
    label: 'Tags',
    align: 'left',
    visible: false,
    filterable: false,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'date',
    type: 'date',
    label: 'Date',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Amount ($)',
    align: 'right',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'withAdvantage',
    type: 'boolean',
    label: 'With Advantage',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'amountEligible',
    type: 'currency',
    label: 'Amount Eligible ($)',
    align: 'right',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'amountAdvantage',
    type: 'currency',
    label: 'Amount Advantage ($)',
    align: 'right',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'category',
    label: 'Category',
    align: 'left',
    visible: true,
    filterable: false,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'paymentMethod',
    type: 'string',
    label: 'Payment Method',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'receipt',
    type: 'string',
    label: 'Receipt',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'nonReceiptable',
    type: 'boolean',
    label: 'Non-Receiptable',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'notes',
    type: 'string',
    label: 'Donation Notes',
    align: 'left',
    visible: false,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
];
