import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

export * from './category';
export * from './paymentMethod';
export * from './donation';
export * from './pledge';
export * from './donor';
export * from './organization';
export * from './receipt';
export * from './view';
export * from './stripe';
export * from './tag';
export * from './user';
export * from './recurringDonation';
export * from './importData';
export * from './bankDeposit';
export * from './cashCount';

// enables functions to be used locally
// ----------------------------------------------------------------------
if (import.meta.env.VITE_APP_LOCAL_FUNCTIONS === 'true') {
  connectFunctionsEmulator(getFunctions(firebaseApp), 'localhost', 5001);
}

export const onRequest = async <T>(url: string): Promise<T> => {
  const res = await fetch(`${import.meta.env.VITE_APP_FUNCTIONS_BASE_URL}/${url}`);
  const body: { data: T } = await res.json();
  return body.data;
};
