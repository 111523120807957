import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import type { CashCount } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// export const cashCountGetAllForOrg = httpsCallable<
//   CashCount.GetAllForOrgReq,
//   CashCount.GetAllForOrgRes
// >(firebaseFunctions, 'cashCount-getAllForOrg');

// ----------------------------------------------------------------------
// Create
export const cashCountCreate = httpsCallable<CashCount.CreateReq, CashCount.CreateRes>(
  firebaseFunctions,
  'cashCount-create'
);

// ----------------------------------------------------------------------
// Update
export const cashCountUpdate = httpsCallable<CashCount.UpdateReq, CashCount.UpdateRes>(
  firebaseFunctions,
  'cashCount-update'
);

// ----------------------------------------------------------------------
// Delete
export const cashCountDelete = httpsCallable<CashCount.DeleteReq, CashCount.DeleteRes>(
  firebaseFunctions,
  'cashCount-deleteRecord'
);
