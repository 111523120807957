import { Donor } from '@shared/types';

/* 
  The function has three main modes of operation:
  1. If **none of these arguments are provided** (`dateFrom`, `dateTo`, or `currentYear`), it returns the empty string.
  2. If **`dateFrom` and `dateTo` are not provided**, it will return the member number for the `currentYear`.
  3. If a **date range** is provided (with `dateFrom` and `dateTo`), it will return the member number within that date range.
*/

export default function getMemberEnvNumber(
  donor: Donor.Donor,
  currentYear: number,
  dateFrom?: Date,
  dateTo?: Date
) {
  const donorMemberNumbers = donor.memberNumbers || {}; // Object containing member numbers for each year

  if (!dateFrom && !dateTo) {
    // Use current year for "all time" range
    return donorMemberNumbers[currentYear] || '';
  }

  const fromYear = dateFrom ? dateFrom.getFullYear() : currentYear;
  const toYear = dateTo ? dateTo.getFullYear() : currentYear;

  // Check if the date range is fully within one year
  if (fromYear === toYear) {
    return donorMemberNumbers[fromYear] || '';
  }

  // If it includes the current year, use the current year's number
  if (toYear >= currentYear && fromYear <= currentYear) {
    return donorMemberNumbers[currentYear] || '';
  }

  // Use the latest year within the range that has a member/env. #
  for (let year = toYear; year >= fromYear; year--) {
    if (donorMemberNumbers[year]) {
      return donorMemberNumbers[year];
    }
  }

  return ''; // No member/env. # found for the range
}
