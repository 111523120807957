import {
  Tag,
  Donor,
  Donation,
  Receipt,
  Address,
  Category,
  PaymentMethod,
  Pledge,
  BankDeposit,
  CashCount,
} from '@shared/types';
import { RecurringDonation } from '@shared/types/recurringDonation';

// ----------------------------------------------------------------------
// WARNING: data here is for all organizations user owns, useDonation does the slicing!
export type TDonationState = {
  bankDeposits: BankDeposit.BankDeposit[];
  cashCounts: CashCount.CashCount[];
  donors: Donor.Donor[];
  donations: Donation.Donation[];
  recurringDonations: RecurringDonation[];
  pledges: Pledge.Pledge[];
  categories: Category.Category[];
  paymentMethods: PaymentMethod.PaymentMethod[];
  tags: Tag.Tag[];
  receipts: Receipt.Receipt[];
};

// ----------------------------------------------------------------------
// Types
// state payload used for startup
export type ListenerData = {
  bankDeposits?: BankDeposit.BankDeposit[];
  cashCounts?: CashCount.CashCount[];
  donors?: Donor.Donor[];
  donations?: Donation.Donation[];
  recurringDonations?: RecurringDonation[];
  pledges?: Pledge.Pledge[];
  categories?: Category.Category[];
  paymentMethods?: PaymentMethod.PaymentMethod[];
  tags?: Tag.Tag[];
  receipts?: Receipt.Receipt[];
};

// simplified data type for the app - might get expensive!
export type TDonorWithDonations = Donor.Donor & {
  donations: Donation.Donation[];
  donationsTotal: number;
  donationDate?: string | Date;
};

// simplified data type for the app - might get expensive!
// This adds in the total of the donations that satisfy the pledge to the pledge details.
export type TPledgeWithActual = Pledge.Pledge & { donationsTotal: number };

// data injected for receipt templates
export type TReceiptVariables = {
  readonly 'org.name': string;
  readonly 'org.address': Address;
  readonly 'org.fullAddress': string;
  readonly 'org.number'?: string;
  readonly 'org.phone'?: string;
  readonly 'org.logo'?: { url: string; width: number; height: number };
  readonly 'org.religiousBenefit'?: boolean;

  readonly 'signatory.name'?: string;
  readonly 'signatory.position'?: string;
  readonly 'signatory.signature'?: { url: string; width: number; height: number };
  readonly 'signatory.email'?: string;

  readonly 'donor.firstName': string;
  readonly 'donor.middleName': string;
  readonly 'donor.lastName': string;
  readonly 'donor.fullName': string;
  readonly 'donor.fullLegalName': string;
  readonly 'donor.reversedName': string;
  readonly 'donor.email'?: string;
  readonly 'donor.address'?: Address;
  readonly 'donor.fullAddress'?: string;
  readonly 'donations.totalByYear': { [year: number]: string };
  readonly 'donations.thisYearTotal': string;
  readonly 'donations.lifetimeTotal': string;

  // helpers
  readonly fDate: (param: number | string | Date) => string;
  readonly fLongDate: (param: number | string | Date) => string;
  readonly fCurrency: (param: number | string) => string;
  readonly fReceiptNumber: (number: number, year: number) => string;
  readonly getTagsFromIds: (tagIds: string[]) => Tag.Tag[];
  readonly getCategoryById: (catId: string) => Category.Category | undefined;
  readonly getPaymentMethodById: (
    paymentMethodId: string | undefined
  ) => PaymentMethod.PaymentMethod | undefined;
  readonly getPaymentMethod: (paymentMethodId?: string, paymentInfo?: string) => string;
};

export type TReceiptRenderGroup = {
  readonly date: string | Date;
  readonly number: number;
  readonly year: number;

  readonly donations: Donation.Donation[];

  // NOTE: receipt can be a duplicate / correction of another receipt
  readonly type?: Receipt.ReceiptActionType;
  readonly linkNumber?: number;
};

export type TReceiptDonorGroup = {
  donorId: string;
  vars: TReceiptVariables;
  groups: TReceiptRenderGroup[];
};

export const donorsViewCacheId = 's4np-view-donors-v1';
export const donationsViewCacheId = 's4np-view-donations-v1';
export const recurringDonationsViewCacheId = 's4np-view-recurring-donations-v1';
export const receiptsViewCacheId = 's4np-view-receipts-v1';
