import { PDFViewer as PDFViewerRenderer } from '@react-pdf/renderer';
import { Stack, Typography } from '@mui/material';
import { ComponentProps, useEffect } from 'react';

import type { ReportDocumentProps } from '../ReportDocument';
import { useRenderPDF } from './useRenderPDF';

// ----------------------------------------------------------------------
type Props = Omit<ComponentProps<typeof PDFViewerRenderer>, 'children'> & {
  onComplete: (blob: Blob) => void;
  onLoading?: (isLoading: boolean) => void;
  documentProps: ReportDocumentProps;
};

// ----------------------------------------------------------------------
export default function RenderedPDFViewer({
  className,
  onComplete,
  onLoading,
  documentProps,
  innerRef,
  showToolbar = false,
  ...props
}: Props) {
  const { pdfUrl, pdfBlob, loading, chunk, total, error } = useRenderPDF(documentProps);

  useEffect(() => {
    onLoading && onLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!loading && pdfBlob) {
      onComplete(pdfBlob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfBlob]);

  const src = pdfUrl ? `${pdfUrl}#toolbar=${showToolbar ? 1 : 0}` : null;
  const style = { backgroundColor: 'grey', width: '100%', height: '100%' };
  if (loading) {
    return (
      <Stack
        className={className}
        style={style}
        alignItems="center"
        justifyContent="center"
        color="white"
      >
        <Typography variant="h6">Generating preview...</Typography>
        <Typography variant="h6">
          {chunk !== 0 ? `${Math.round((chunk / total) * 100)}%` : ' '}
        </Typography>
      </Stack>
    );
  }

  if (error) {
    console.log({ error });
    return (
      <div className={className} style={style}>
        {JSON.stringify(error)}
      </div>
    );
  }

  return (
    <iframe
      title="PDFViewer"
      // @ts-ignore
      src={src}
      ref={innerRef}
      // @ts-ignore
      style={style}
      className={className}
      {...props}
    />
  );
}
