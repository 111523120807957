import { useMemo, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
  PDFViewer,
  BlobProvider,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { filter, groupBy, sortBy } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTable, PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportLayout from '../../ReportLayout';
import ReportHeader from '@pages/reports/ReportHeader';
import { Donation } from '@shared/types/donation';
import useLocalState from '@hooks/useLocalState';
import {
  AvailableSortOrder,
  SortOrder,
  sortByOrders,
  orderDescription,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

type OneDateReportOptions = {
  showDescription: boolean;
  includeDetails: boolean;
  includeSummary: boolean;
};

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: 'Lato',
    fontSize: 10,
  },
  summaryTableHeader: {
    paddingTop: 10,
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    minHeight: 18,
  },
  tableRowItem: {
    padding: '2px 4px 0',
    width: '50%',
  },
});

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToDayStart,
    fDateToDayEnd,
    getDateFormat,
    fLongDate,
    isDateBetween,
    fCurrency,
    fReversedName,
  } = useFormat();
  const { donors, donations, getCategoryById, getPaymentMethod, getPaymentMethodById } =
    useDonation();
  const { org } = useOrg();
  const storageKey = 's4np-report-one-date-v0';

  // ---------- form state
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([{ availablesIndex: 0 }]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const { current: initOptions } = useRef<OneDateReportOptions>({
    showDescription: false,
    includeDetails: true,
    includeSummary: true,
  });

  const [options, setOptions] = useLocalState<OneDateReportOptions>(storageKey, initOptions);

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Name', field: (d) => d.name.toUpperCase() },
      { label: 'Amount', field: 'amount' },
      { label: 'Category', field: (d) => d.category.toUpperCase() },
      { label: 'Payment Method', field: (d) => d.paymentMethod.toUpperCase() },
    ],
    []
  );

  const filtered: Donation[] = useMemo(
    () =>
      filter(donations, (d) =>
        isDateBetween(new Date(d.date), fDateToDayStart(selectedDate), fDateToDayEnd(selectedDate))
      ),
    [donations, selectedDate, isDateBetween, fDateToDayStart, fDateToDayEnd]
  );

  const detailsTableData: PdfTableData = useMemo(() => {
    let header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 26 },
      { name: 'Amount ($)', width: 12, align: 'right' },
      { name: 'Category', width: 20 },
      { name: 'Payment Method', width: 24 },
    ];
    if (options.showDescription) {
      header[3].width = 20;
      header.push({ name: 'Description', width: 20 });
    }

    const total = filtered.reduce((n, { amount }) => n + Number(amount), 0);
    const grouped = groupBy(filtered, 'donorId');
    const itemsUnsorted = Object.keys(grouped)
      .map((donorId) => {
        const donor = donors.find((d) => d.id === donorId);
        return grouped[donorId].map((donation) => ({
          name: fReversedName(donor, selectedDate.getFullYear()),
          amount: donation.amount,
          category: getCategoryById(donation.categoryId)?.name || '',
          paymentMethod: getPaymentMethod(donation.paymentMethodId, donation.paymentInfo),
          description: donation.description || '',
        }));
      })
      .flat();
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((d) => {
      let row = [d.name, fCurrency(d.amount), d.category, d.paymentMethod];
      if (options.showDescription) row.push(d.description);
      return row;
    });
    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: `Totals: ${items.length} donations` },
      { columns: 1, text: `${fCurrency(total)}` },
    ];

    return { header, items, summary };
  }, [options.showDescription, filtered, sortOrders, availableSortOrders, fCurrency, donors, fReversedName, selectedDate, getCategoryById, getPaymentMethod]);

  const categoryTableData: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Category', width: 50 },
      { name: 'Amount ($)', width: 20, align: 'right' },
    ];

    const total = filtered.reduce((n, { amount }) => n + Number(amount), 0);
    const grouped = groupBy(filtered, 'categoryId');
    const itemsUnsorted = Object.keys(grouped).map((categoryId) => {
      const category = getCategoryById(categoryId);
      const categoryTotal = grouped[categoryId].reduce((t, { amount }) => t + Number(amount), 0);
      return [category?.name, fCurrency(categoryTotal)] as string[];
    });
    const items = sortBy(itemsUnsorted, (item) => item[0].toUpperCase());

    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: 'Total:' },
      { columns: 1, text: `${fCurrency(total)}` },
    ];

    return { header, items, summary };
  }, [filtered, fCurrency, getCategoryById]);

  const paymentMethodTableData: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Payment Method', width: 50 },
      { name: 'Amount ($)', width: 20, align: 'right' },
    ];

    const total = filtered.reduce((n, { amount }) => n + Number(amount), 0);
    const grouped = groupBy(filtered, 'paymentMethodId');
    const itemsUnsorted = Object.keys(grouped).map((paymentMethodId) => {
      const paymentMethodLabel = getPaymentMethodById(paymentMethodId)?.name || '';
      const methodTotal = grouped[paymentMethodId].reduce((t, { amount }) => t + Number(amount), 0);
      return [paymentMethodLabel, fCurrency(methodTotal)] as string[];
    });
    const items = sortBy(itemsUnsorted, (item) => item[0].toUpperCase());

    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: 'Total:' },
      { columns: 1, text: `${fCurrency(total)}` },
    ];

    return { header, items, summary };
  }, [filtered, fCurrency, getPaymentMethodById]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const { width, height } = PageSize[size];

  // --------------------------------------------------
  // Because this report can have one or three PdfTables in it, have to replicate
  // the code from ReportDocument.
  const ReportDoc = useMemo(() => {
    const pageSize =
      orientation === 'landscape' ? { width: height, height: width } : { width, height };
    return (
      <Document
        title={`${name} - ${fLongDate(new Date())}`}
        subject={description}
        author="Software4Nonprofits"
        creator="s4np web-app"
        producer="s4np web-app"
        pdfVersion="1.7"

        // onRender={(blob) => console.log('doc render: ', blob)}
      >
        {/* US letter size is different than A4 iso size */}
        {/* below is calculated using 72dpi */}
        <Page style={styles.page} size={pageSize} wrap>
          <ReportHeader
            title={name}
            dateReport={new Date()}
            dateFrom={selectedDate}
            dateTo={selectedDate}
            orderBy={
              options.includeDetails ? orderDescription(sortOrders, availableSortOrders) : ''
            }
            orgName={org!.name}
          />
          {options.includeDetails && <PdfTable data={detailsTableData} />}
          {options.includeSummary && (
            <View style={styles.tableRow}>
              <View style={styles.tableRowItem}>
                <Text style={styles.summaryTableHeader}>Summary by Category</Text>
                <PdfTable data={categoryTableData} />
              </View>
              <View style={styles.tableRowItem}>
                <Text style={styles.summaryTableHeader}>Summary by Payment Method</Text>
                <PdfTable data={paymentMethodTableData} />
              </View>
            </View>
          )}
        </Page>
      </Document>
    );
  }, [
    orientation,
    height,
    width,
    name,
    fLongDate,
    description,
    selectedDate,
    options.includeDetails,
    options.includeSummary,
    sortOrders,
    availableSortOrders,
    org,
    detailsTableData,
    categoryTableData,
    paymentMethodTableData,
  ]);

  const partialUpdateOptions = (newObj: Partial<OneDateReportOptions>) => {
    const newOptions = { ...options, ...newObj };
    setOptions(newOptions);
  };

  const tableDatas: PdfTableData[] = useMemo(() => {
    let datas: PdfTableData[] = [];
    if (options.includeDetails) datas.push(detailsTableData);
    if (options.includeSummary) {
      datas.push(categoryTableData);
      datas.push(paymentMethodTableData);
    }
    return datas;
  }, [
    categoryTableData,
    detailsTableData,
    options.includeDetails,
    options.includeSummary,
    paymentMethodTableData,
  ]);

  return (
    <Dialog
      title="One Date Donations Report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!isDateValid(selectedDate) || !blob || !!error}
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={tableDatas}
        pdfBlob={pdfBlob}
        basename={'one_date_donations'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            {options.includeDetails && (
              <SortDialog
                availableOrders={availableSortOrders}
                currentOrders={sortOrders}
                setOrders={setSortOrders}
                sortButtonText="Sort Details Table"
              />
            )}
            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date
              </Typography>
              <DesktopDatePicker
                format={getDateFormat()}
                label="Date"
                value={selectedDate}
                onChange={(v, context) => {
                  !context.validationError && v && setSelectedDate(v);
                }}
              />
              <Typography variant="button" sx={{ textTransform: 'inherit' }}>
                Sections to Include
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={options.includeDetails}
                      onChange={() => {
                        partialUpdateOptions({ includeDetails: !options.includeDetails });
                      }}
                    />
                  }
                  label={'Donation Details'}
                />
                {options.includeDetails && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={options.showDescription}
                        onChange={() => {
                          partialUpdateOptions({ showDescription: !options.showDescription });
                        }}
                      />
                    }
                    label={'Add Description Column'}
                    sx={{ paddingLeft: 4 }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={options.includeSummary}
                      onChange={() => {
                        partialUpdateOptions({ includeSummary: !options.includeSummary });
                      }}
                    />
                  }
                  label={'Summary Sections'}
                />
              </FormGroup>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
