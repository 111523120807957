import { User } from './user';
import { Organization } from './organization';

export * as Category from './category';
export * as PaymentMethod from './paymentMethod';
export * as Donation from './donation';
export * as Donor from './donor';
export * as ImportData from './importData';
export * as Invitation from './invitation';
export * as Organization from './organization';
export * as Pledge from './pledge';
export * as Receipt from './receipt';
export * as Stripe from './stripe';
export * as View from './view';
export * as Tag from './tag';
export * as User from './user';
export * as RecurringDonation from './recurringDonation';
export * as BankDeposit from './bankDeposit';
export * as CashCount from './cashCount';

export type Modify<T, R> = Omit<T, keyof R> & R;
export type BaseKeys = 'id' | '_meta';

export type TBatchItem<T> = {
  readonly id: string;
  readonly update: Omit<Partial<T>, BaseKeys>;
};
export type TBatch<T> = TBatchItem<T>[];
export type TUpdateBatch<T> = {
  readonly userId: string;
  readonly items: TBatch<T>;
};
export type TCreateBatch<T> = {
  readonly userId: string;
  readonly items: T[];
};

// Meta exists on every document as meta-properties
export type Meta = {
  readonly createdAt: string;
  readonly createdBy: string;
  readonly updatedAt: string;
  readonly updatedBy: string;

  // archive at is a date when CRON should archive the document
  readonly archiveAt?: string;
  // confirmation and when it was archived
  readonly archived: boolean;
  readonly archivedAt?: string;
  readonly archivedBy?: string;
};

export enum Country {
  ca = 'ca',
  us = 'us',
}

export type Address = {
  readonly address1?: string;
  readonly address2?: string;
  readonly city?: string;
  readonly state?: string | CanadianState;
  readonly postalCode?: string;
  readonly country: Country;
};

export enum CanadianState {
  ab = 'ab',
  bc = 'bc',
  mb = 'mb',
  nl = 'nl',
  nb = 'nb',
  nt = 'nt',
  ns = 'ns',
  nu = 'nu',
  on = 'on',
  pe = 'pe',
  qc = 'qc',
  sk = 'sk',
  yt = 'yt',
}

export type Feedback = {
  readonly user: User;
  readonly org: Organization;
  readonly donorCount: number;
  readonly donationCount: number;

  readonly context?: string;
  readonly text: string;
};

// TODO: could be used for request data manipulation
// export type Collate = {
//   order: 'asc' | 'desc';
//   orderBy: string;
//   rowsPerPage: number;

//   // filter?: string;
//   // filterBy?: string;
//   // filter?: string; // TODO: filter always on whatever is the orderBy!

//   cursor?: {
//     id: string;
//     direction: 'previous' | 'next';
//   };
// };
