import { useEffect, useState } from 'react';
import { proxy, wrap } from 'comlink';
import type { WorkerType } from './pdf.worker';
import Worker from './pdf.worker?worker';

// ----------------------------------------------------------------------
export const pdfWorker = wrap<WorkerType>(new Worker());
// uncomment to enable worker logging
// pdfWorker.onLog(proxy((info: any) => console.log('pdf-worker:', info, new Date().getTime())));
pdfWorker.onLog(proxy((info: any) => {}));

// ----------------------------------------------------------------------
export const useRenderPDF = (PDFProps: Parameters<WorkerType['renderPDFInWorker']>[0]) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [renderValue, setRenderValue] = useState<{ url: string; blob: Blob }>();
  const [[chunk, total], setChunks] = useState([0, 0]);

  pdfWorker.onProgress(proxy((chunk, total) => setChunks([chunk, total])));

  const render = async () => {
    setChunks([0, 0]);
    setRenderValue(undefined);
    setError(undefined);
    setLoading(true);
    try {
      const value = await pdfWorker.renderPDFInWorker(PDFProps);
      setRenderValue(value);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const {
    data: { items, ...pdfData },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dateReport,
    ...pdfProps
  } = PDFProps;

  const cacheData = JSON.stringify(pdfData);
  const cacheProps = JSON.stringify(pdfProps);

  useEffect(() => {
    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheProps, cacheData, items.length]);

  return { pdfUrl: renderValue?.url, pdfBlob: renderValue?.blob, loading, chunk, total, error };
};
