// ----------------------------------------------------------------------
export type TAppState = {
  hasError: boolean;
};

// ----------------------------------------------------------------------
export type AutocompleteItem<T = string, R = string> = {
  readonly value: T;
  readonly label: string;
  readonly helperText?: string;
  readonly related?: R[];
};

// ----------------------------------------------------------------------
export type PageOrientation = 'portrait' | 'landscape';

export const PageSize = {
  letter: { value: 'Letter', width: 612, height: 792 },
  a4: { value: 'A4', width: 595, height: 842 },
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PageSize = keyof typeof PageSize;

// ----------------------------------------------------------------------
export type TColumnType =
  | 'string'
  | 'number'
  | 'date'
  | 'address'
  | 'currency'
  | 'checkbox'
  | 'boolean';
export type TColumn<T = string> = {
  readonly id: T;
  readonly label: string;
  readonly visible: boolean;

  readonly align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  readonly width?: number;
  readonly minWidth?: number;
  readonly pdfWidth?: number; // use for proportional widths within PDF, narrowest should be 10
  readonly type?: TColumnType;

  readonly internal?: boolean;
  readonly filterable?: boolean;
  readonly searchable?: boolean;
  readonly disabled?: boolean; // setting this to true for a column will filter it out from the list of displayed and displayable columns
};

export enum ColumnFilterOperator {
  contains = 'contains',
  equals = 'equals',
  notEquals = 'notEquals',
  greaterThan = 'startsWith',
  lessThan = 'endsWith',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
}

// NOTE: this has to match TableFilters:evaluateFilter
export const ColumnFilterOperatorString = [
  { value: ColumnFilterOperator.contains, label: 'contains' },
  { value: ColumnFilterOperator.equals, label: 'equals' },
  { value: ColumnFilterOperator.notEquals, label: 'is not equal to' },
  { value: ColumnFilterOperator.greaterThan, label: 'starts with' },
  { value: ColumnFilterOperator.lessThan, label: 'ends with' },
  { value: ColumnFilterOperator.isEmpty, label: 'is empty' },
  { value: ColumnFilterOperator.isNotEmpty, label: 'is not empty' },
];
export const ColumnFilterOperatorNumber = [
  { value: ColumnFilterOperator.equals, label: 'equals' },
  { value: ColumnFilterOperator.notEquals, label: 'is not equal to' },
  { value: ColumnFilterOperator.greaterThan, label: 'is greater than' },
  { value: ColumnFilterOperator.lessThan, label: 'is less than' },
  { value: ColumnFilterOperator.isEmpty, label: 'is empty' },
  { value: ColumnFilterOperator.isNotEmpty, label: 'is not empty' },
];
export const ColumnFilterOperatorDate = [
  { value: ColumnFilterOperator.equals, label: 'equals' },
  { value: ColumnFilterOperator.notEquals, label: 'is not equal to' },
  { value: ColumnFilterOperator.greaterThan, label: 'is after' },
  { value: ColumnFilterOperator.lessThan, label: 'is before' },
  { value: ColumnFilterOperator.isEmpty, label: 'is empty' },
  { value: ColumnFilterOperator.isNotEmpty, label: 'is not empty' },
];
export const ColumnFilterOperatorBoolean = [
  { value: ColumnFilterOperator.isNotEmpty, label: 'Yes' },
  { value: ColumnFilterOperator.isEmpty, label: 'No' },
];

export type TColumnFilter<T = string> = {
  readonly id: string;
  readonly columnId: T;
  readonly columnType: TColumnType;
  readonly operator: ColumnFilterOperator;
  readonly value: string;
};
