import { useEffect, useMemo } from 'react';
import { Alert, Stack, TextareaAutosize } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import useOrg from '@hooks/useOrg';
import useAuth from '@hooks/useAuth';
import useDonation from '@hooks/useDonation';
import { feedbackSchemaResolver, FeedbackSchemaForm } from '@/schemas';
import { RHFTextField } from '@components/hook-form';
import Dialog from '@components/Dialog';
import EmptyContent from '@components/EmptyContent';
import { ActiveSubscriptionStatuses } from '@shared/types/stripe';

// ----------------------------------------------------------------------
type Props = {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
};

// ----------------------------------------------------------------------
export default function DeleteDialog({ open, setOpen }: Props) {
  const { user } = useAuth();
  const { donors, donations } = useDonation();
  const { org, submitFeedback, archiveOrg } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  const subscriptionStatus = org?.subscriptions?.donation?.subscriptionStatus || '';
  const hasActiveSubscription = Object.values(ActiveSubscriptionStatuses).includes(subscriptionStatus as ActiveSubscriptionStatuses);

  // --------------- effects ---------------
  const defaultValues: FeedbackSchemaForm = useMemo(
    () => ({
      topic: 'Reason for Deleting',
      text: '',
    }),
    []
  );

  // --------------- form ---------------
  const methods = useForm<FeedbackSchemaForm>({
    resolver: feedbackSchemaResolver,
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = methods;

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  // --------------- actions ---------------
  const onSubmit = async (data: FeedbackSchemaForm) => {
    if (!user || !org) {
      setError('afterSubmit', { message: 'Internal error, contact support' });
      return null;
    }

    if (hasActiveSubscription) {
      setError('afterSubmit', { message: 'Please cancel your subscription first' });
      return null;
    }

    try {
      await submitFeedback({
        org,
        user,
        context: data.topic,
        text: data.text,
        donorCount: donors.length,
        donationCount: donations.length,
      });
      await archiveOrg({ orgId: org.id });
      enqueueSnackbar(`Organization deleted!`);
      setOpen(false);
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={setOpen}
      title="Organization Deletion"
      isDirty={isDirty}
      isLoading={isSubmitting}
      methods={methods}
      maxWidth="sm"
      actions={
        <LoadingButton
          size="large"
          variant="contained"
          color="error"
          loading={isSubmitting}
          disabled={!isValid || hasActiveSubscription}
          onClick={handleSubmit(onSubmit)}
        >
          Permanently Delete Organization
        </LoadingButton>
      }
    >
      <Stack
        spacing={1}
        alignItems="center"
        justifyContent="center"
        sx={{ width: '80%', margin: '0 auto' }}
      >
        {hasActiveSubscription
          ? <EmptyContent
              title="Cannot Delete Organization"
              description="Please note that your paid subscription must be canceled before the organization can be deleted."
          />
          : <RHFTextField
              name="text"
              type="text"
              label="Reason"
              disabled={isSubmitting}
              fullWidth
              required
              InputProps={{
                inputComponent: TextareaAutosize,
              }}
              inputProps={{
                minRows: 4,
                rows: 4,
                maxRows: 6,
              }}
            />
        }

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>
    </Dialog>
  );
}
