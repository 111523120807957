import { Address, Meta } from '../index';
import { Subscription } from '../stripe';
export * from './requests';

// ----------------------------------------------------------------------
export type OrgUser = {
  readonly id: string;
  readonly role: Role;
  readonly displayName: string;
  readonly email: string;
};

export type OrgLock = {
  readonly isLocked: boolean;
  readonly lockedAt?: string;
  readonly lockedBy?: string;
  readonly unlockedAt?: string;
  readonly unlockedBy?: string;
};

export type MigrationLock = {
  readonly isLocked: boolean;
  readonly lockedAt?: string;
  readonly unlockedAt?: string;
};

export enum Currency {
  usd = 'usd',
  cad = 'cad',
}

// user has a single role in an organization
// role is a group of Permissions
export enum Role {
  owner = 'owner',
  editor = 'editor',
  contributor = 'contributor',
  readOnly = 'readOnly',
  donorOnly = 'donorOnly',
  // limited = 'limited',
}

// each Permission is a fine-grained access to a specific module
// its part of Role
export enum Permission {
  manageOrg = 0,
  manageDonors = 1,
  viewDonors = 2,
  manageDonations = 3,
  viewDonations = 4,
  manageReceipts = 5,
  viewReceipts = 6,
}

export const RolePermissions = {
  [Role.owner]: [
    Permission.manageOrg,
    Permission.manageDonors,
    Permission.viewDonors,
    Permission.manageDonations,
    Permission.viewDonations,
    Permission.manageReceipts,
    Permission.viewReceipts,
  ],
  [Role.editor]: [
    Permission.manageOrg,
    Permission.manageDonors,
    Permission.viewDonors,
    Permission.manageDonations,
    Permission.viewDonations,
    Permission.manageReceipts,
    Permission.viewReceipts,
  ],
  [Role.contributor]: [
    Permission.manageOrg,
    Permission.manageDonors,
    Permission.viewDonors,
    Permission.manageDonations,
    Permission.viewDonations,
  ],
  [Role.donorOnly]: [Permission.manageDonors, Permission.viewDonors], // [1,2]
  [Role.readOnly]: [Permission.viewDonors, Permission.viewDonations, Permission.viewReceipts],
};

// ----------------------------------------------------------------------
export type Organization = {
  id: string;
  readonly _meta: Meta;

  readonly name: string;
  readonly registrationNumber?: string;
  readonly address: Address;
  readonly phone?: string;
  readonly users: { [userId: string]: OrgUser };

  // preferences
  readonly currency: Currency;
  readonly language: string;
  readonly dateFormat: string;
  readonly timeFormat: string;
  readonly memberNumbers: boolean;

  // receipts
  readonly receiptYear: { [year: number]: number };
  readonly signatoryName: string;
  readonly signatoryPosition?: string;
  readonly signatorySignature?: string;
  readonly logo?: string;
  readonly religiousBenefit: boolean;

  readonly lock: OrgLock;
  // Keep it optional so that we don't need to do a migration
  readonly migrationLock?: MigrationLock;

  // email configuration
  readonly emailSignature?: PostmarkSignature | GmailSignature;

  // subscription
  readonly subscriptions?: Subscription;

  // receipt email template
  readonly receiptEmailSubject?: string;
  readonly receiptEmailBody?: string;

  // options for bottom section of receipt
  readonly receiptDetailsTable?: boolean;
  readonly receiptDetailsTableDescription?: boolean;
  readonly receiptSummaryTable?: boolean;

  // defaults
  readonly defaultCategoryId?: string;
  readonly defaultPaymentMethodId?: string;
  readonly defaultPledgeCategoryId?: string;

  // v1 migration
  readonly migrationCount?: number;
  readonly migrationLimit?: number;
  readonly v1CustomerId?: string;
  readonly v1StripeMigrationDone?: boolean;

  // V1 fields added during the migration
  readonly guid?: string;
  readonly v1Version?: V1Version;
  readonly v1ExpiryDate?: string;
  readonly v1MigratedUser?: boolean;
  readonly v1MigrationDates?: string[];

  // added separately in S4NP-286
  readonly donorCustomFields?: DonorCustomField[];

  readonly pledgesEnabled?: boolean;

  // object with settings for bank deposits
  readonly bankDepositSettings?: BankDepositSettings;
};

export enum V1Version {
  OnDemand = 'OnDemand',
  Standard = 'Standard',
  LocalNetworkServer = 'Local Network Server',
  LocalNetworkClient = 'Local Network Client',
  FreeLite = 'Free Lite',
}

export type BankDepositSettings = {
  readonly type: BankDepositAccountingProgram;
  readonly useClasses: boolean; // true if for QBD or QBO, classes are used
  readonly dayFirst: boolean; // true if exports to QBD use the date format DD/MM/YYYY instead of the standard MM/DD/YYYY
};

export enum BankDepositAccountingProgram {
  None = 'No Processing',
  ACCOUNTS = 'ACCOUNTS',
  QBD = 'QuickBooks Desktop',
  QBO = 'QuickBooks Online',
  Other = 'Other',
}

export type DonorCustomField = { name: string; visible: boolean };
// ----------------------------------------------------------------------

export enum EmailSignatureType {
  customDomain = 'customDomain',
  gmail = 'gmail',
}

export type PostmarkSignature = {
  readonly signatureId: number;
  readonly signatoryName: string;
  readonly signatoryEmail: string;
  readonly confirmed: boolean;
  readonly type: EmailSignatureType;
};

export type GmailSignature = {
  readonly accessToken: string;
  readonly refreshToken: string;
  readonly signatoryName: string;
  readonly signatoryEmail: string;
  readonly confirmed: boolean;
  readonly type: EmailSignatureType;
};

export type GmailSignatureSignatoryAuth = Omit<
  GmailSignature,
  'signatoryEmail' | 'accessToken' | 'refreshToken'
>;
// ----------------------------------------------------------------------
