import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import type { BankDeposit } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// export const bankDepositGetAllForOrg = httpsCallable<
//   BankDeposit.GetAllForOrgReq,
//   BankDeposit.GetAllForOrgRes
// >(firebaseFunctions, 'bankDeposit-getAllForOrg');

// ----------------------------------------------------------------------
// Create
export const bankDepositCreate = httpsCallable<BankDeposit.CreateReq, BankDeposit.CreateRes>(
  firebaseFunctions,
  'bankDeposit-create'
);

// ----------------------------------------------------------------------
// Update
export const bankDepositUpdate = httpsCallable<BankDeposit.UpdateReq, BankDeposit.UpdateRes>(
  firebaseFunctions,
  'bankDeposit-update'
);

// ----------------------------------------------------------------------
// Delete
export const bankDepositDelete = httpsCallable<BankDeposit.DeleteReq, BankDeposit.DeleteRes>(
  firebaseFunctions,
  'bankDeposit-deleteRecord'
);
