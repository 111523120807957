import { useState } from 'react';
import { TableRow, TableCell, MenuItem } from '@mui/material';

import { TColumn } from '@typedefs/app';
import { TableMoreMenu } from '@components/table';
import Iconify from '@components/Iconify';
import { TagItem } from '@pages/donors/dialogs/Settings/DonorTags/utils/renderTags';
import { CategoryItem } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import type { DonorListHeader, DonorListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<DonorListHeader>[];
  readonly item: DonorListItem;
  readonly onEdit: (id: string) => void;
};

// ----------------------------------------------------------------------
export default function Row({ item, onEdit, columns }: Props) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(null);
  };

  const cells = columns
    .filter((c) => (c.visible || ['options'].includes(c.id)) && !c.disabled)
    .map((column) => {
      switch (column.id) {
        case 'tags':
          return (
            <TableCell key={column.id} align={column.align}>
              {item._tags.map((tag) => (
                <TagItem key={tag.id} tag={tag} />
              ))}
            </TableCell>
          );
        case 'categories':
          return (
            <TableCell key={column.id} align={column.align}>
              {item._categories.map((c) => (
                <CategoryItem key={c.id} category={c} />
              ))}
            </TableCell>
          );
        case 'options':
          return (
            <TableCell key={column.id} align="right">
              <TableMoreMenu
                open={openMenu}
                onOpen={handleOpenMenu}
                onClose={handleCloseMenu}
                actions={
                  <>
                    <MenuItem
                      onClick={(e) => {
                        onEdit(item.id);
                        handleCloseMenu(e);
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>
                  </>
                }
              />
            </TableCell>
          );
        default:
          const value = item[column.id] || '';
          return (
            <TableCell
              key={column.id}
              align={column.align}
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            />
          );
      }
    });

  return (
    <TableRow
      hover
      onClick={() => onEdit(item.id)}
      sx={{ cursor: 'pointer', '& strong': { color: (theme) => theme.palette.secondary.light } }}
    >
      {cells}
    </TableRow>
  );
}
