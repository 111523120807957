import { analytics } from '@/firebase/app';
import * as firebaseAnalytics from 'firebase/analytics';

// ----------------------------------------------------------------------
// donor
export function donorCreate() {
  firebaseAnalytics.logEvent(analytics, 'donor_create');
}

export function donorView() {
  firebaseAnalytics.logEvent(analytics, 'donor_view');
}

export function donorUpdate() {
  firebaseAnalytics.logEvent(analytics, 'donor_update');
}

export function donorDelete() {
  firebaseAnalytics.logEvent(analytics, 'donor_delete');
}

// ----------------------------------------------------------------------
// donation
export function donationCreate() {
  firebaseAnalytics.logEvent(analytics, 'donation_create');
}

export function donationBatchCreate(count: number) {
  firebaseAnalytics.logEvent(analytics, 'donation_batch_create', { count });
}

export function donationView() {
  firebaseAnalytics.logEvent(analytics, 'donation_view');
}

export function donationUpdate() {
  firebaseAnalytics.logEvent(analytics, 'donation_update');
}

export function donationDelete() {
  firebaseAnalytics.logEvent(analytics, 'donation_delete');
}

// recurring donation
export function recurringDonationCreate() {
  firebaseAnalytics.logEvent(analytics, 'recurring_donation_create');
}

export function recurringDonationView() {
  firebaseAnalytics.logEvent(analytics, 'recurring_donation_view');
}

export function recurringDonationUpdate() {
  firebaseAnalytics.logEvent(analytics, 'recurring_donation_update');
}

export function recurringDonationDelete() {
  firebaseAnalytics.logEvent(analytics, 'recurring_donation_delete');
}

// ----------------------------------------------------------------------
// pledge
export function pledgeCreate() {
  firebaseAnalytics.logEvent(analytics, 'pledge_create');
}

export function pledgeView() {
  firebaseAnalytics.logEvent(analytics, 'pledge_view');
}

export function pledgeUpdate() {
  firebaseAnalytics.logEvent(analytics, 'pledge_update');
}

export function pledgeDelete() {
  firebaseAnalytics.logEvent(analytics, 'pledge_delete');
}

// ----------------------------------------------------------------------
// category
export function categoryCreate() {
  firebaseAnalytics.logEvent(analytics, 'category_create');
}

export function categoryView() {
  firebaseAnalytics.logEvent(analytics, 'category_view');
}

export function categoryUpdate() {
  firebaseAnalytics.logEvent(analytics, 'category_update');
}

export function categoryDelete() {
  firebaseAnalytics.logEvent(analytics, 'category_delete');
}

// ----------------------------------------------------------------------
// paymentMethod
export function paymentMethodCreate() {
  firebaseAnalytics.logEvent(analytics, 'paymentMethod_create');
}

export function paymentMethodView() {
  firebaseAnalytics.logEvent(analytics, 'paymentMethod_view');
}

export function paymentMethodUpdate() {
  firebaseAnalytics.logEvent(analytics, 'paymentMethod_update');
}

export function paymentMethodDelete() {
  firebaseAnalytics.logEvent(analytics, 'paymentMethod_delete');
}

// ----------------------------------------------------------------------
// tags
export function tagCreate() {
  firebaseAnalytics.logEvent(analytics, 'tag_create');
}

export function tagView() {
  firebaseAnalytics.logEvent(analytics, 'tag_view');
}

export function tagUpdate() {
  firebaseAnalytics.logEvent(analytics, 'tag_update');
}

export function tagDelete() {
  firebaseAnalytics.logEvent(analytics, 'tag_delete');
}

// ----------------------------------------------------------------------
// reports
export function reportView(reportType: string) {
  firebaseAnalytics.logEvent(analytics, 'report_view', { report_type: reportType });
}

export function reportDownloadPDF(reportType: string) {
  firebaseAnalytics.logEvent(analytics, 'report_download_pdf', { report_type: reportType });
}

export function reportDownloadCSV(reportType: string) {
  firebaseAnalytics.logEvent(analytics, 'report_download_csv', { report_type: reportType });
}

export function exportPDF() {
  firebaseAnalytics.logEvent(analytics, 'export_pdf');
}

export function exportCSV() {
  firebaseAnalytics.logEvent(analytics, 'export_csv');
}

// ----------------------------------------------------------------------
// receipts
export function receiptCreate(count: number, type: string) {
  firebaseAnalytics.logEvent(analytics, 'receipt_create', { count, type });
}

export function receiptReissue(count: number, type: string) {
  firebaseAnalytics.logEvent(analytics, 'receipt_reissue', { count, type });
}

export function receiptView() {
  firebaseAnalytics.logEvent(analytics, 'receipt_view');
}

// export function receiptDelete() {
//   firebaseAnalytics.logEvent(analytics, 'receipt_delete');
// }

// ----------------------------------------------------------------------
// member numbers
export function memberAssignView() {
  firebaseAnalytics.logEvent(analytics, 'member_assign_view');
}

export function memberAssign() {
  firebaseAnalytics.logEvent(analytics, 'member_assign');
}

// ----------------------------------------------------------------------
// bankDeposit
export function bankDepositCreate() {
  firebaseAnalytics.logEvent(analytics, 'bankDeposit_create');
}

export function bankDepositView() {
  firebaseAnalytics.logEvent(analytics, 'bankDeposit_view');
}

export function bankDepositUpdate() {
  firebaseAnalytics.logEvent(analytics, 'bankDeposit_update');
}

export function bankDepositDelete() {
  firebaseAnalytics.logEvent(analytics, 'bankDeposit_delete');
}

// ----------------------------------------------------------------------
// cashCount
export function cashCountCreate() {
  firebaseAnalytics.logEvent(analytics, 'cashCount_create');
}

export function cashCountView() {
  firebaseAnalytics.logEvent(analytics, 'cashCount_view');
}

export function cashCountUpdate() {
  firebaseAnalytics.logEvent(analytics, 'cashCount_update');
}

export function cashCountDelete() {
  firebaseAnalytics.logEvent(analytics, 'cashCount_delete');
}


// ----------------------------------------------------------------------
// Import Data
export function importData() {
  firebaseAnalytics.logEvent(analytics, 'import_data');
}
