import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Box, Card, Grid, Table, TableBody, TableContainer, TablePagination } from '@mui/material';

import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import useTable, { emptyRows } from '@hooks/useTable';
import { Tag, Category, View } from '@shared/types';
import { TColumnFilter } from '@typedefs/app';
import { donorsViewCacheId } from '@typedefs/donation';
import useDonation from '@hooks/useDonation';

import Scrollbar from '@components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import Toolbar from '../components/Toolbar';
import { DonorsOutletContext } from '../useContext';
import Actions from '../Actions';
import { DONOR_LIST_HEADERS, DonorListHeader } from './config';
import useData from './useData';
import Row from './Row';
import useOrg from '@hooks/useOrg';

// ----------------------------------------------------------------------
type Props = { cachedView?: View.View; clearCache: VoidFunction };
// ----------------------------------------------------------------------
export default function DonorList({ cachedView, clearCache }: Props) {
  const { org } = useOrg();
  const navigate = useNavigate();
  const { getTagsFromIds, getCategoriesFromIds, getDonorCustomField } = useDonation();

  const {
    columns,
    dateFrom,
    dateTo,
    order,
    orderBy,
    page,
    rowsPerPage,
    filters,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    setColumns,
    setDateFrom,
    setDateTo,
    setPage,
    setFilters,
  } = useTable<DonorListHeader>(
    {
      defaultRowsPerPage: 25,
      defaultColumns: DONOR_LIST_HEADERS,
      defaultOrderBy: 'name',
      defaultDateFrom: undefined,
      defaultDateTo: undefined,
    },
    donorsViewCacheId
  );

  const [search, setSearch] = useState('');
  const [filterTags, setFilterTags] = useState<Tag.Tag[]>(() => {
    const cachedTags = localStorage.getItem(donorsViewCacheId + '-tags');
    const tags = cachedTags ? JSON.parse(cachedTags) : [];
    return tags;
  });
  const [filterCategories, setFilterCategories] = useState<Category.Category[]>(() => {
    const cachedCats = localStorage.getItem(donorsViewCacheId + '-categories');
    const categories = cachedCats ? JSON.parse(cachedCats) : [];
    return categories;
  });

  useEffect(() => {
    if (cachedView && cachedView?.type === 'donor') {
      const columns = DONOR_LIST_HEADERS.map((h) => ({
        ...h,
        visible: cachedView.config.columnIds.includes(h.id),
      }));
      setColumns(columns);
      onSort(cachedView.config.orderBy as DonorListHeader, cachedView.config.order);
      setFilters(cachedView.config.filters as TColumnFilter[]);
      handleFilterTagChange(getTagsFromIds(cachedView.config.tagIds));
      handleFilterCatChange(getCategoriesFromIds(cachedView.config.categoryIds));
      clearCache();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedView]);

  // return the custom field based on an id such as "custom1" ... "custom6"
  const getDonorCustomFieldById = (id: string) => getDonorCustomField(parseInt(id.charAt(6)));

  useEffect(() => {
    setColumns(
      columns.map((c) => {
        // first handle the more complex custom fields
        if (c.id.startsWith('custom')) {
          const field = getDonorCustomFieldById(c.id);
          return { ...c, disabled: !field.visible, label: field.name };
        }
        // all other fields
        return c.id === 'memberNumber' ? { ...c, disabled: !org?.memberNumbers } : c;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.memberNumbers, org?.donorCustomFields]);

  const handleSearchChange = (search: string) => {
    setPage(0);
    setSearch(search);
  };

  const handleFilterTagChange = (newValue: Tag.Tag[]) => {
    setFilterTags(newValue);
    localStorage.setItem(donorsViewCacheId + '-tags', JSON.stringify(newValue));
  };

  const handleFilterCatChange = (newValue: Category.Category[]) => {
    setFilterCategories(newValue);
    localStorage.setItem(donorsViewCacheId + '-categories', JSON.stringify(newValue));
  };

  const handleEdit = (donorId: string) => {
    navigate(getPath(PATHS.org.donors.donor.edit, { donorId }));
  };

  // ----- FILTERING -------
  const data = useData({
    columns,
    dateFrom,
    dateTo,
    order,
    orderBy,
    search,

    filters: filters as TColumnFilter<DonorListHeader>[],
    filterTags,
    filterCategories,
  });

  const outletContext: DonorsOutletContext = {
    type: 'donor',
    data,

    columns,
    dateFrom,
    dateTo,
    order,
    orderBy: DONOR_LIST_HEADERS.find((h) => h.id === orderBy) || DONOR_LIST_HEADERS[2],

    filterCategories,
    filterTags,
    filters,
  };

  return (
    <Grid container spacing={2}>
      <Outlet context={outletContext} />

      <Grid item xs={12} md={12}>
        <Toolbar
          columns={columns}
          setColumns={setColumns}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          search={search}
          onSearchChange={handleSearchChange}
          filterProps={{
            columns,
            filterTags,
            onFilterTagChange: handleFilterTagChange,
            filterCategories,
            onFilterCategoryChange: handleFilterCatChange,
            filters,
            setFilters,
          }}
          actions={<Actions />}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <TableContainer sx={{ minWidth: '100%', position: 'relative', overflow: 'unset' }}>
            <Scrollbar
            // sx={{ maxHeight: 200 }}
            >
              <Table stickyHeader size="small">
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  columns={columns}
                  rowCount={data.length}
                  onSort={onSort as (id: string) => void}
                />

                <TableBody>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <Row key={item.id} columns={columns} item={item} onEdit={handleEdit} />
                  ))}

                  <TableEmptyRows
                    height={52}
                    emptyRows={emptyRows(page, rowsPerPage, data.length)}
                  />

                  <TableNoData isNotFound={!data.length} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
