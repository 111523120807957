import { useMemo } from 'react';
import {
  orderBy as _orderBy,
  cloneDeep,
  filter,
  intersection,
  isString,
  last,
  sortBy,
} from 'lodash';
import Fuse from 'fuse.js';

import { TColumn, TColumnFilter } from '@typedefs/app';
import { Category, Tag } from '@shared/types';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import evaluateFilter from '@components/table/TableFilters/evaluateFilter';
import { DonationListHeader, DonationListItem } from './config';
import getMemberEnvNumber from '@utils/getMemberEnvNumber';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<DonationListHeader>[];
  readonly dateFrom?: Date;
  readonly dateTo?: Date;
  readonly order: 'asc' | 'desc';
  readonly orderBy: DonationListHeader;
  readonly search: string;

  // filters
  readonly filterTags: Tag.Tag[];
  readonly filterCategories: Category.Category[];
  readonly filters: TColumnFilter[];
};
// ----------------------------------------------------------------------
export default function useData({ columns, search, order, orderBy, ...filters }: Props) {
  const {
    donations,
    getDonationDonor,
    getTagsFromIds,
    getCategoryById,
    getReceiptById,
    getPaymentMethod,
  } = useDonation();
  const { fFullName, fReversedName, fDate, fCurrency, fReceiptNumber } = useFormat();
  // We are forcing all columns to be searchable, if we don't omit the condition in the next
  // line, stored views will have them non-searchable!
  const searchableColumns = columns.filter((c) => c.visible /* && c.searchable */).map((c) => c.id);
  const currentYear = new Date().getFullYear();

  // ----- parsing -------
  const parsedData = useMemo(
    () =>
      donations.map((donation): DonationListItem => {
        const donor = getDonationDonor(donation);
        const category = getCategoryById(donation.categoryId);
        const tags = sortBy(getTagsFromIds(donor?.tagIds || []), (t) => t.name.toUpperCase());
        const receipt = getReceiptById(last(donation.receiptIds));
        const paymentMethodName = getPaymentMethod(donation.paymentMethodId, donation.paymentInfo);

        return {
          id: donation.id,
          _donation: donation,
          _tags: tags,
          _category: category,

          donorId: donation.donorId,
          receiptId: receipt?.id,

          name: fFullName(donor, currentYear, filters.dateFrom, filters.dateTo),
          reversedName: fReversedName(donor, currentYear, filters.dateFrom, filters.dateTo),
          email: donor?.email || '',
          date: fDate(donation.date),
          amount: fCurrency(donation.amount),
          amountEligible: fCurrency(donation.amountEligible || donation.amount),
          amountAdvantage: fCurrency(donation.amountAdvantage || 0),
          withAdvantage: donation.withAdvantage ? 'Yes' : 'No',
          tags: tags.map((c) => c!.name).join(', '),
          category: category?.name || '',
          description: donation.description || '',
          paymentMethod: paymentMethodName,
          notes: donation.notes,
          receipt: receipt
            ? fReceiptNumber(receipt.number, receipt.year)
            : !!donor?.nonReceiptable || !!donation.nonReceiptable
              ? 'Non-Receiptable'
              : '',
          nonReceiptable: !!donor?.nonReceiptable || !!donation.nonReceiptable ? 'Yes' : 'No',
          memberNumber: donor
            ? getMemberEnvNumber(donor, currentYear, filters.dateFrom, filters.dateTo).toString()
            : '',
        };
      }),
    [
      donations,
      getDonationDonor,
      getCategoryById,
      getTagsFromIds,
      getReceiptById,
      getPaymentMethod,
      fFullName,
      currentYear,
      fReversedName,
      fDate,
      fCurrency,
      fReceiptNumber,
      filters,
    ]
  );

  // ----- FILTERING -------
  const filteredData = useMemo(() => applyFilters(parsedData, filters), [parsedData, filters]);

  // ----- SEARCHING -------
  const fuse = useMemo(
    () =>
      new Fuse(filteredData, {
        keys: searchableColumns,
        includeMatches: true,
        includeScore: false,
        threshold: 0.2,
        shouldSort: false,
      }),
    [filteredData, searchableColumns]
  );

  const searchedData = useMemo(
    () =>
      search
        ? fuse.search(search).map(({ item, matches }) => {
            let clone = cloneDeep(item) as any;
            matches?.forEach(({ key, indices }) => {
              let prop = clone[key as string];
              if (isString(prop)) {
                [...indices].reverse().forEach(([start, finish]) => {
                  prop = prop.substring(0, finish + 1) + '</strong>' + prop.substring(finish + 1);
                  prop = prop.substring(0, start) + '<strong>' + prop.substring(start);
                });
                clone[key as string] = prop;
              }
            });

            return clone as DonationListItem;
          })
        : ((fuse as any)._docs as DonationListItem[]),
    [fuse, search]
  );

  // ----- ORDERING -------
  return useMemo(() => {
    switch (orderBy) {
      case 'date':
        return _orderBy(searchedData, (f) => new Date(f._donation.date).getTime(), order);
      case 'amount':
        return _orderBy(searchedData, (f) => f._donation.amount, order);
      case 'amountEligible':
        return _orderBy(searchedData, (f) => f._donation.amountEligible, order);
      case 'amountAdvantage':
        return _orderBy(searchedData, (f) => f._donation.amountAdvantage, order);
      case 'memberNumber':
        return _orderBy(
          searchedData,
          (f) => {
            if (!f.memberNumber) {
              return order === 'asc' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
            }
            return parseInt(f.memberNumber);
          },
          order
        );
      default:
        return _orderBy(searchedData, orderBy, order);
    }
  }, [searchedData, orderBy, order]);
}

// ----------------------------------------------------------------------
type FilterProps = Omit<Props, 'columns' | 'search' | 'order' | 'orderBy'>;
function applyFilters(data: DonationListItem[], dataProps: FilterProps): DonationListItem[] {
  return filter(data, (item) =>
    [
      applyDateFilter(item, dataProps.dateFrom, dataProps.dateTo),
      applyTagsFilter(item, dataProps.filterTags),
      applyCategoriesFilter(item, dataProps.filterCategories),
      applyColumnFilters(item, dataProps.filters),
    ].every(Boolean)
  );
}

// -----
function applyDateFilter(item: DonationListItem, dateFrom?: Date, dateTo?: Date) {
  const date = new Date(item._donation.date).getTime();
  return (dateFrom?.getTime() || 0) <= date && date <= (dateTo?.getTime() || Infinity);
}
function applyTagsFilter(item: DonationListItem, tags: Tag.Tag[]) {
  const tagIds = tags.filter((t) => t.type === 'donor').map((t) => t.id);
  const donorTagIds = item._tags.map((t) => t.id);
  return !tagIds.length || intersection(tagIds, donorTagIds).length > 0;
}
function applyCategoriesFilter(item: DonationListItem, categories: Category.Category[]) {
  const catIds = categories.map((c) => c.id);
  return !catIds.length || catIds.includes(item._category?.id || '');
}

// ------------------------------
const getValue = (item: DonationListItem, columnId: string): any => {
  switch (columnId) {
    case 'date':
      return item._donation.date;
    case 'amount':
      return item._donation.amount;
    case 'amountEligible':
      return item._donation.amount;
    case 'amountAdvantage':
      return item._donation.amount;
    default: {
      return (item as any)[columnId];
    }
  }
};

function applyColumnFilters(item: DonationListItem, filters: TColumnFilter[]) {
  return filters.every((filter) => evaluateFilter(getValue(item, filter.columnId), filter));
}
