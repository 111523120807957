import { useEffect, useMemo } from 'react';
import {
  Alert,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import sortBy from 'lodash/sortBy';

import { User } from '@shared/types/user';
import { Organization, Role } from '@shared/types/organization';
import { RoleList } from '@typedefs/org';
import { OrganizationUsersSchemaForm, organizationUsersSchemaResolver } from '@/schemas';
import useOrg from '@hooks/useOrg';
import useAuth from '@hooks/useAuth';

import Avatar from '@components/Avatar';
import Iconify from '@components/Iconify';
import { FormProvider } from '@components/hook-form';

// ----------------------------------------------------------------------
type Props = {
  readonly org: Organization;
  readonly users: User[];
};

// ----------------------------------------------------------------------
export default function CurrentUsers({ org, users }: Props) {
  const { user: currentUser } = useAuth();
  const { updateOrgUsers } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  // ----- form
  const defaultValues: OrganizationUsersSchemaForm = useMemo(
    () => ({
      users: sortBy(
        Object.values(org.users).map((orgUser) => ({
          user: orgUser,
          role: orgUser.role,
        })),
        'user.displayName'
      ),
    }),
    [org.users]
  );

  const methods = useForm<OrganizationUsersSchemaForm>({
    resolver: organizationUsersSchemaResolver,
    defaultValues,
  });

  const {
    control,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const { fields, update, remove } = useFieldArray({
    control,
    name: 'users',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [users, defaultValues, reset]);

  const handleSave = async (data: OrganizationUsersSchemaForm) => {
    const users: {
      userId: string;
      displayName: string;
      role: Role | undefined;
      email: string;
    }[] = [];
    defaultValues.users.forEach((orgUser) => {
      const formUser = data.users.find((u) => u.user.id === orgUser.user.id);

      // user is removed
      if (!formUser) {
        users.push({
          userId: orgUser.user.id!,
          displayName: orgUser.user.displayName,
          role: undefined,
          email: orgUser.user.email,
        });
      } else if (formUser.role !== orgUser.role) {
        // user has new role
        users.push({
          userId: formUser.user.id!,
          displayName: formUser.user.displayName,
          role: formUser.role,
          email: orgUser.user.email,
        });
      }
    });

    try {
      const res = await updateOrgUsers({ orgId: org.id, update: { users } });

      if (res) {
        enqueueSnackbar('Updated!');
      } else {
        enqueueSnackbar('Failed to update', { variant: 'error' });
        setError('afterSubmit', { message: 'Failed to update' });
      }
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  // ----- content
  return (
    <FormProvider methods={methods}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h5">Current Users</Typography>

          <Stack
            spacing={3}
            sx={{ p: 1 }}
            divider={<Divider sx={{ width: '90%', mx: 'auto !important' }} />}
          >
            {fields.map((f, i) => {
              const isCurrentUser = f.user.id === currentUser?.id;
              return (
                <Stack
                  key={f.id}
                  spacing={1.5}
                  direction={{ sm: 'column', md: 'row' }}
                  alignItems={{ md: 'center' }}
                >
                  <Avatar src="" alt={f.user.displayName} />

                  <Stack sx={{ width: 240, minWidth: 240 }}>
                    <Stack direction="row">
                      {isCurrentUser && (
                        <Typography variant="body2" mr={0.5}>
                          (you)
                        </Typography>
                      )}
                      <Typography variant="h6">{f.user.displayName}</Typography>
                    </Stack>
                    <Typography variant="body2">
                      {users.find((u) => u.id === f.user.id)?.email || ''}
                    </Typography>
                  </Stack>

                  <FormControl
                    fullWidth
                    sx={{
                      m: 1,
                      minHeight: 50,
                    }}
                    disabled={isCurrentUser}
                  >
                    <InputLabel id="org-role">Organization role</InputLabel>
                    <Select
                      labelId="org-role"
                      value={f.role}
                      label="Organization role"
                      onChange={(e) => update(i, { ...f, role: e.target.value as Role })}
                      sx={{ height: 40 }}
                    >
                      {RoleList.map((p) => (
                        <MenuItem key={p.value} value={p.value}>
                          {p.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ mt: 0 }}>
                      {RoleList.find((p) => p.value === f.role)?.helperText}
                    </FormHelperText>
                  </FormControl>

                  {!isCurrentUser ? (
                    <IconButton
                      aria-label="close"
                      onClick={() => remove(i)}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <Iconify icon="material-symbols:close" />
                    </IconButton>
                  ) : (
                    <Stack minWidth={40} />
                  )}
                </Stack>
              );
            })}
          </Stack>

          <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <Stack
            spacing={2}
            direction={{ xs: 'column-reverse', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-end"
          >
            {isDirty && (
              <Button variant="text" onClick={() => reset()}>
                Clear
              </Button>
            )}
            <LoadingButton
              size="large"
              variant="contained"
              loading={isSubmitting}
              disabled={!isDirty}
              onClick={handleSubmit(handleSave)}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </FormProvider>
  );
}
